import {
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Button,
} from "@material-ui/core"

import InputBase from "@material-ui/core/InputBase"

import { makeStyles, withStyles } from "@material-ui/core/styles"

import React, { useState } from "react"
import { Row, Col } from "react-bootstrap"

//TODO https://github.com/ubilabs/react-geosuggest
const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase)

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
}))
const GesucheSearchBar = ({ onSearchClick }) => {
  const [art, setArt] = useState("")
  const [type, setType] = useState("")
  const [location, setLocation] = useState(undefined)
  const [distance, setDistance] = useState(10)

  const handleChangeArt = event => setArt(event.target.value)
  const handleChangeType = event => setType(event.target.value)
  const handleChangeDistance = event => setDistance(event.target.value)
  const onSuggestSelect = suggest => setLocation(suggest && suggest.location)

  return (
    <Paper style={{ background: "rgba(255,255,255,0.9)", padding: "1em" }}>
      <Row>
        <Col md ={12}>
          <FormControl fullWidth>
            <InputLabel></InputLabel>
            <Select
              native
              // value={values.salutation}
              input={<BootstrapInput name="age" id="age-customized-select" />}
              onChange={handleChangeArt}
            >
              <option value="" disabled selected>
                Pachtgesuch / Kaufgesuch
              </option>
              <option value={"Pachtgesuch"}>Pachtgesuch</option>
              <option value={"Kaufgesuch"}>Kaufgesuch</option>
            </Select>
            {/* <FormHelperText>Auto width</FormHelperText> */}
          </FormControl>
        </Col>
        <Col>
          <FormControl fullWidth>
            <InputLabel></InputLabel>
            <Select
              native
              // value={values.salutation}
              input={<BootstrapInput name="age" id="age-customized-select" />}
              onChange={handleChangeType}
            >
              <option value="" disabled selected>
                Objekttyp
              </option>
              <option value="Ackerland">Ackerfläche</option>
              <option value="Forstfläche Wald">Forstfläche Wald</option>
              <option value="Betrieb/ Anwesen/ Bauernhof">Betrieb/ Anwesen/ Bauernhof</option>
              <option value="Hofstelle">Hofstelle</option>
              <option value="Wohnimmobilien">Wohnimmobilien</option>
              <option value="Gewerbeimmobilien">Gewerbeimmobilien</option>
              <option value="Sonstige  Immobilien">Sonstige  Immobilien</option>
              
            </Select>
            {/* <FormHelperText>Auto width</FormHelperText> */}
          </FormControl>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col style={{ textAlign: "right" }}>
          <Button
            onClick={() => onSearchClick({ type, art, location, distance })}
            variant="outlined"
            size="large"
          >
            Suchen
          </Button>
        </Col>
      </Row>
    </Paper>
  )
}
export default GesucheSearchBar
