import React from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from "react-bootstrap"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
function calculateSomething(kaufpreis, flaeche) {
  console.log(kaufpreis)
  return (kaufpreis / flaeche).toFixed(2)
}
function generateHeadline(art) {
  switch (art){
    case "Pachtgesuch": return "Pachtpreis";
    case "Kaufgesuch": return "Kaufpreis";
   

    default: return "Kaufpreis";

  }


  function generateSpace(space){
    switch (space){
      case "Vermieten": return "Mietfläche";
      case "Verkaufen": return "Größe";
      case "Verpachten": return "Pachtfläche";
    }
  }}
const GesucheTeaserPost = ({ data, post }) => {
  console.log(data)
  return (
    <Row  className="listing-container">
      <Col md={4} className="text-center">
      {post.node.acf_request.beitragsbild && post.node.acf_request.beitragsbild.imageFile ? (
            <Img
              className="img-fluid"
              sizes={post.node.acf_request.beitragsbild.imageFile.childImageSharp.sizes}
              alt={post.node.title}
            />
          ) : (
            <Img
            className="img-fluid"
            fixed={data.logoImmobilien.childImageSharp.fixed}
               />
          )}
      </Col>
      <Col md={8}>
        <Row>
          <Col xs={12}>
         
            <h3
              dangerouslySetInnerHTML={{ __html: post.node.title }}
              style={{ marginBottom: 0 }}
            />
           
            <div
              dangerouslySetInnerHTML={{
                __html: post.node.acf_request.lagebeschreibung,
              }}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <strong>
                {!post.node.acf_request.cost &&
                  post.node.acf_request.costcustomtext}
                {post.node.acf_request.cost &&
                  new Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: "EUR",
                    minimumFractionDigits:0,
                  maximumFractionDigits:0,
                  }).format(post.node.acf_request.cost)}{" "}</strong>
            <br />
            {generateHeadline(post.node.acf_request.art)}
          </Col>
          <Col>
            <strong>
              {post.node.acf_request.flaeche} {post.node.acf_request.flaeche_typ}
            </strong>
            <br />
            Fläche
          </Col>
        </Row>
        <Row>
          <Col>
            <div
              dangerouslySetInnerHTML={{
                __html: post.node.acf_request.objektbeschreibung,
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

GesucheTeaserPost.propTypes = {}

export default GesucheTeaserPost
