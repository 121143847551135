import React, { useState } from "react"
import { graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import GesucheSearchBar from "../components/GesucheSearchBar"
import GesucheTeaserPost from "../components/GesucheTeaserPost"

import { Select, withStyles } from "@material-ui/core"

import InputBase from "@material-ui/core/InputBase"

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase)

const Gesuche = ({ data }) => {
  const [parameters, setParameters] = useState({
    type: "",
    art: ""
  
  })

  //so würde es dann richtig aussehen
  const [sortBy, setSortBy] = useState("flaecheAbsteigend")

  const handleSearchClick = parameters => {
    setParameters(parameters)
    console.log("hallo", parameters)
  }

  const sortHandleChange = event => {
    setSortBy(event.target.value)
  }
 

  const filterLogic = post => {
    let shouldStayInArray = true
    if (parameters.art !== "")
      shouldStayInArray = post.node.acf_request.art === parameters.art
    if (parameters.type !== "" && shouldStayInArray )
      shouldStayInArray = post.node.acf_request.objekttyp === parameters.type
      
   
    
    return shouldStayInArray
  }

  const sortLogic = (a, b) => {
    switch (sortBy) {
      
      case "priceAscending":
        if (a.node.acf_request.cost < b.node.acf_request.cost) return -1
        else if (a.node.acf_request.cost === b.node.acf_request.cost) return 0
        else return 1

      case "priceDescending":
        if (a.node.acf_request.cost < b.node.acf_request.cost) return 1
        else if (a.node.acf_request.cost === b.node.acf_request.cost) return 0
        else return -1

      case "areaAscending":
        if (a.node.acf_request.flaeche < b.node.acf_request.flaeche) return 1
        else if (a.node.acf_request.flaeche === b.node.acf_request.flaeche) return 0
        else return -1

      case "areaDescending":
        if (a.node.acf_request.flaeche < b.node.acf_request.flaeche) return -1
        else if (a.node.acf_request.flaeche === b.node.acf_request.flaeche) return 0
        else return 1
      default:
        return 0
    }

    console.log(sortBy)
  }



  return (
    
    <Layout>
      {/* //TODO SEO */}
      <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />

      <Container fluid>
        <Row id="portal" className="d-flex  align-items-center">
          <Col  md={{ span: 6, offset: 3 }} sm={12} xs={12}>
            <GesucheSearchBar onSearchClick={handleSearchClick} />
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className="mb-3">
          <Col className="mt-3">
            <Select
              native
              onChange={sortHandleChange}
              input={<BootstrapInput name="" id="" />}
            >
              <option value="" disabled selected>
                Sortieren nach
              </option>
              <option value={"priceAscending"}>Preis aufsteigend</option>
              <option value={"priceDescending"}>Preis absteigend</option>
              <option value={"areaAscending"}>Fläche aufsteigend</option>
              <option value={"areaDescending"}>Fläche absteigend</option>
            </Select>
          </Col>
        </Row>
        {data.wpgraphql.requests.edges
         .filter(filterLogic)
          .sort((a, b) => sortLogic(a, b))
          .map(post => (
            <GesucheTeaserPost post={post} data={data} />
          ))}
      </Container>
    </Layout>
  )
}

export default Gesuche

export const query = graphql`
  query {
    wpgraphql{
    requests {
      edges {
        node {
          acf_request {
            art
            flaeche
            cost
            costcustomtext
            objekttyp
            objektbeschreibung
            beitragsbild {
              mediaItemUrl
              sourceUrl
              mediaItemId
              modified
              imageFile {
                childImageSharp {
                  sizes(maxWidth: 600) {
                    ...GatsbyImageSharpSizes
                  }
                }
              }}
          }
       
          title
          slug
          date
        }
      }
    }}
    logoImmobilien: file(relativePath: { eq: "logo_immo.png" }) {
      ...squareImage
    }
  }
`
